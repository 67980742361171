import React from "react"
import Style from "./carousel-simple.style"
import Card, { CarouselSimpleCardProps } from "./card"
import useResizeWidth from "../../../hooks/useResizeWidth"
import useMousePosition from "../../../hooks/useMousePosition"
import "../carousel.css"

import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, {
  EffectFade,
  Navigation,
  Pagination,
  Controller,
} from "swiper"
SwiperCore.use([Pagination, EffectFade, Navigation, Controller])
import "swiper/swiper.scss"
import "swiper/swiper-bundle.min.css"
import "swiper/swiper.min.css"

export interface CarouselSimpleProps {
  content: {
    __typename: string
    cards: CarouselSimpleCardProps[]
    title: string
    subtitle: string
  }
}

const CarouselSimple = ({ content }: CarouselSimpleProps) => {
  const [my_swiper, set_my_swiper] = React.useState({}) //mouseoverclick event

  const { width } = useResizeWidth()
  const { position } = useMousePosition()
  // mouseover event
  let mouseLocation
  if (position.x > width / 2) {
    mouseLocation = "right"
  } else if (position.x <= width / 2) {
    mouseLocation = "left"
  } else {
    return
  }
  return (
    <>
      <Style.Container>
        <Style.SectionTitle>{content.title}</Style.SectionTitle>
        <Style.SectionSubtitle>{content.subtitle}</Style.SectionSubtitle>
        <Style.FlexContainer
          mouseLocation={mouseLocation}
          onClick={
            mouseLocation === "right"
              ? () => {
                  my_swiper.slideNext()
                }
              : () => {
                  my_swiper.slidePrev()
                }
          }
        >
          <Swiper
            onInit={ev => {
              set_my_swiper(ev)
            }}
            centeredSlides={true}
            loop={true}
            slidesPerView={"auto"}
            spaceBetween={30}
            className="swiper-simple-carousel"
            pagination={{
              bulletElement: "div",
              clickable: true,
              dynamicBullets: true,
              dynamicMainBullets: 3,
              bulletClass: "custom-bullet-white",
              modifierClass: "swiper-pagination-simple-carousel",
            }}
            containerModifierClass="swiper-container-simple-carousel"
          >
            {content.cards.map((card, i) => (
              <SwiperSlide key={i}>
                <Card card={card} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Style.FlexContainer>
      </Style.Container>
    </>
  )
}

export default CarouselSimple
