import React from "react"
import Style from "./card-with-tag.style"
import Img, { FluidObject } from "gatsby-image"
import { Link } from "gatsby"

export interface CardWithTagProps {
  card: {
    cardBgHexColor: string
    ctaLabel: string
    ctaUrl: string
    tagName: string
    tagBgHexColor: string
    title: string
    pattern: {
      fluid: FluidObject
      title: string
    }
    image: {
      fluid: FluidObject
      title: string
    }
  }
}

const CardWithTag = ({ card }: CardWithTagProps) => {
  return (
    <>
      <Style.GridContainer>
        <Style.SolidColor bgColor={card.cardBgHexColor}>
          <Style.FlexContainer>
            <Style.Tag bgColor={card.tagBgHexColor}>{card.tagName}</Style.Tag>
            <Style.Title> {card.title}</Style.Title>
            <Style.Cta>
              <Link to={card.ctaUrl} style={{ textDecoration: "none" }}>
                {card.ctaLabel}
              </Link>
            </Style.Cta>
          </Style.FlexContainer>
        </Style.SolidColor>
        <Style.Pattern>
          <Style.Image>
            <Img
              fluid={card.image.fluid}
              alt={card.image.title}
              style={{ width: "100%", height: "100%" }}
            />
          </Style.Image>
          <Img
            fluid={card.pattern.fluid}
            alt={card.pattern.title}
            style={{ width: "100%", height: "100%" }}
          />
        </Style.Pattern>
      </Style.GridContainer>
    </>
  )
}

export default CardWithTag
