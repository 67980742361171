import Img from "gatsby-image"
import styled from "styled-components"
import { COLORS, MOBILE_MAX_WIDTH, IPAD_MINI_MAX_WIDTH } from "../../../styles"
import DownPurpleArrowIcon from "../../../images/arrow-down.svg"

export default {
  Hero: {
    Container: styled.div`
      width: 100%;
      display: flex;
      position: relative;
      overflow: hidden;
      height: 100vh;
      @media screen and (min-width: 768px) {
        height: 90vh;
      }
    `,
    Video: styled.video`
      position: absolute;
      top: 0;
      right: 50%;
      width: 1080px;
      height: 100%;
      object-fit: fill;
      transform: translateX(50%);
      @media screen and (min-width: 414px) {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: translateX(0);
      }
    `,
    // MobileImage: styled.img`
    //   object-fit: contain;
    //   @media screen and (min-width: 768px) {
    //     display: none;
    //   }
    // `,
    Image: styled.img`
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      object-fit: cover;
    `,
  },

  ScrollDownContainer: styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: transparent;
    position: absolute;
    z-index: 2;
    bottom: 0;
  `,
  ScrollDown: styled.div`
    display: flex;
    justify-content: center;
    width: 120px;
    height: 50px;
    color: ${COLORS.WHITE};
    background-color: ${COLORS.HOLLY_GREEN};
    cursor: pointer;
    align-items: center;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      width: 120px;
    }
  `,
  ScrollDownIcon: styled(DownPurpleArrowIcon)`
    fill: ${COLORS.WHITE};
  `,
}
