import styled from "styled-components"
import { COLORS, FONTS, IPAD_MINI_MAX_WIDTH } from "../../../styles"

export default {
  Container: styled.div`
    margin-top: 30px;
    padding-bottom: 30px;
    background-color: #${props => props.bgColor};
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      margin-top: 40px;
      padding-bottom: 80px;
    }
    .swiper-pagination-filter-carouselbullets {
      cursor: auto;
      bottom: 0;
      left: 50%;
      display: flex;
      justify-content: start;
      width: fit-content !important;
      transform: translate(-50%, 0px) !important;
      z-index: 10 !important;
    }
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      .swiper-pagination-filter-carouselbullets {
        padding: 50px 50px 0 50px;
      }
    }
  `,
  SectionTitle: styled.div`
    font-family: ${FONTS.SOHNE_LEICHT};
    font-size: 38px;
    color: ${COLORS.WHITE};
    text-align: center;
    padding: 50px 0 20px 0;
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      font-size: 48px;
      padding: 0 0 20px 0;
      transform: translateY(-20px);
    }
  `,
  FlexContainer: styled.div`
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      display: flex;
      &: not(.swiper-pagination-filter-carouselbullets) {
        ${props =>
          props.mouseLocation === "right"
            ? `cursor: url(/images/right-arrow.svg) 0 0, auto;`
            : `cursor: url(/images/left-arrow.svg) 0 0, auto;`};
      }
    }
    @media screen and (min-width: 850px) {
      width: 100%;
    }
  `,

  SectionFilter: styled.div`
    display: flex;
    justify-content: flex-start;
    padding: 10px 0 0 0;
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      width: 50%;
      margin: 0 auto;
      padding: 0 0 20px 0;
      justify-content: space-between;
    }
  `,
  TagButton: styled.div``,
  TagButtonText: styled.button`
    white-space: nowrap;
    font-family: ${FONTS.SOHNE_BUCH};
    font-size: 12px;
    color: ${COLORS.WHITE};
    background-color: transparent;
    border: 0;
    text-transform: uppercase;
    cursor: pointer;
    margin: 0 auto;
    height: 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid transparent;
    transition: all 0.2s ease;
    &:hover,
    &:active,
    &:focus {
      border-bottom: 1px solid ${COLORS.WHITE};
    }
  `,
}
