import React from "react"
import Style from "./hero.style"
import { Link } from "gatsby"
import { Props } from "../../../pages/holiday"

const HolidayPageHero = ({ data }: Props) => {
  const {
    gif,
    video,
    bannerMediaFormat,
    mobileImage,
  } = data.contentfulHolidayLandingPage

  return (
    <>
      <Style.Hero.Container>
        {video && bannerMediaFormat === "video" && (
          <>
            <Style.Hero.Video
              autoPlay={true}
              controls={false}
              playsInline
              muted
            >
              <source src={video.file.url} type="video/mp4" />
              <img
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                src={mobileImage.file.url}
                title="Your browser does not support the video tag."
              />
            </Style.Hero.Video>
          </>
        )}
        {gif && bannerMediaFormat === "gif" && (
          <Style.Hero.Image
            src={gif.file.url}
            alt={gif.title}
          ></Style.Hero.Image>
        )}
        <Style.ScrollDownContainer>
          <Link to="/holiday#5th_holiday_events">
            <Style.ScrollDown>
              <Style.ScrollDownIcon />
            </Style.ScrollDown>
          </Link>
        </Style.ScrollDownContainer>
      </Style.Hero.Container>
    </>
  )
}

export default HolidayPageHero
