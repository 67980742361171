import styled from "styled-components"
import { COLORS, IPAD_MINI_MAX_WIDTH } from "../../styles"

export default {
  Root: styled.div`
    background-color: ${COLORS.HOLLY_GREEN};
    padding-bottom: 30px;
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      padding-bottom: 40px;
    }
  `,
}
