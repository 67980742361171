import React from "react"
import Style from "./instagram-carousel.style"
import Img, { FluidObject } from "gatsby-image"
import useResizeWidth from "../../../hooks/useResizeWidth"
import useMousePosition from "../../../hooks/useMousePosition"
import "../carousel.css"

import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, {
  EffectFade,
  Navigation,
  Pagination,
  Controller,
} from "swiper"
SwiperCore.use([Pagination, EffectFade, Navigation, Controller])
import "swiper/swiper.scss"
import "swiper/swiper-bundle.min.css"
import "swiper/swiper.min.css"

export interface InstagramCarouselCard {
  image: {
    fluid: FluidObject
    title: string
  }
  name: string
}

export interface InstagramCarouselProps {
  content: {
    __typename: string
    title: string
    subtitle: string
    cards: InstagramCarouselCard[]
  }
}

const InstagramCarousel = ({ content }: InstagramCarouselProps) => {
  const [my_swiper, set_my_swiper] = React.useState({}) //mouseoverclick event
  const { width } = useResizeWidth()
  const { position } = useMousePosition()
  // mouseover event
  let mouseLocation
  if (position.x > width / 2) {
    mouseLocation = "right"
  } else if (position.x <= width / 2) {
    mouseLocation = "left"
  } else {
    return
  }
  return (
    <>
      <Style.Container>
        <Style.SectionTitle>{content.title}</Style.SectionTitle>
        <Style.SectionSubtitle>{content.subtitle}</Style.SectionSubtitle>
        <Style.FlexContainer
          mouseLocation={mouseLocation}
          onClick={
            mouseLocation === "right"
              ? () => {
                  my_swiper.slideNext()
                }
              : () => {
                  my_swiper.slidePrev()
                }
          }
        >
          <Swiper
            onInit={ev => {
              set_my_swiper(ev)
            }}
            centeredSlides={true}
            slidesPerView={"auto"}
            spaceBetween={20}
            className="swiper-instagram-carousel"
            loop={true}
            pagination={{
              bulletElement: "div",
              clickable: true,
              dynamicBullets: true,
              dynamicMainBullets: 3,
              bulletClass: "custom-bullet-white",
              modifierClass: "swiper-pagination-instagram-carousel",
            }}
            containerModifierClass="swiper-container-padding-lg"
          >
            {content.cards.map((card, i) => (
              <SwiperSlide key={i}>
                <Style.Card>
                  <Style.Image
                    fluid={card.image.fluid}
                    alt={card.image.title}
                  />
                  <Style.Name>{card.name}</Style.Name>
                </Style.Card>
              </SwiperSlide>
            ))}
          </Swiper>
        </Style.FlexContainer>
      </Style.Container>
    </>
  )
}

export default InstagramCarousel
