import Styles from "@mapbox/mapbox-sdk/services/styles"
import styled from "styled-components"
import { COLORS, FONTS, IPAD_MINI_MAX_WIDTH } from "../../../styles"
import { CTA } from "../../common/cta"

export default {
  GridContainer: styled.div`
    flex: 1;

    height: 620px;
    margin: 40px auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: minmax(470px, auto) minmax(150px, auto);
    gap: 0px 0px;
    grid-template-areas:
      "SolidColor"
      "Pattern";
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      width: 100%;

      height: 425px;
      grid-template-rows: 100%;
      grid-template-columns: minmax(60%, 500px) minmax(40%, 280px);
      grid-template-areas: "SolidColor Pattern";
    }
  `,
  // grid-template-columns: minmax(500px, auto) minmax(280px, auto);
  SolidColor: styled.div`
    grid-area: SolidColor;
    background-color: #${props => props.bgColor};
  `,
  Pattern: styled.div`
    grid-area: Pattern;
    position: relative;
  `,

  FlexContainer: styled.div`
    color: ${COLORS.BOTTLE_GREEN};
    display: flex;
    flex-direction: column;
    padding: 30px;
    height: calc(100% - 100px);
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      justify-content: flex-end;
      padding: 50px;
      max-width: 350px;
    }
  `,
  Tag: styled.div`
    font-size: 12px;
    font-family: ${FONTS.SOHNE_HALBFETT};
    background-color: #${props => props.bgColor};
    text-transform: uppercase;
    max-width: fit-content;
    padding: 8px 10px;
    margin-bottom: 30px;
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      margin-bottom: auto;
    }
  `,
  Title: styled.div`
    font-size: 30px;
    font-family: ${FONTS.SOHNE_LEICHT};
    line-height: 1.2;
    padding-bottom: 30px;
    width: 80%;
    text-transform: capitalize;
  `,
  Cta: styled(CTA)`
    font-size: 11px;
    text-transform: uppercase;
    font-family: ${FONTS.SOHNE_BUCH};
  `,
  Image: styled.div`
    width: 280px;
    height: 280px;
    overflow: hidden;
    position: absolute;
    z-index: 2;
    left: 50%;
    transform: translate(-50%, -160px);
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      width: 325px;
      height: 325px;
      right: 0;
      top: 50%;
      transform: translate(-230px, -50%);
    }
  `,
}
