import { useEffect, useState } from "react"

const getWidth = () => window.innerWidth

export default function useResizeWidth() {
  const [width, setWidth] = useState<number>(0)
  const handleSetWidth = () => setWidth(getWidth())
  useEffect(() => {
    handleSetWidth()

    window.addEventListener("resize", handleSetWidth)
    return () => window.removeEventListener("resize", handleSetWidth)
  }, [])

  return { width }
}
