import styled from "styled-components"
import {
  FONTS,
  MOBILE_MAX_WIDTH,
  IPAD_MINI_MAX_WIDTH,
  IPHONE5_MAX_WIDTH,
} from "../../../styles"

export default {
  GridContainer: styled.div`
    padding: 30px 0;
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      padding: 40px 0;
    }
    display: grid;
    width: 100%;
    grid-template-columns: 20% minmax(290px, 70%) 10%;
    grid-template-rows: 157px 157px auto;
    gap: 0px 0px;
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      position: relative;
      min-width: 650px;
      width: 90%;
      margin: 0 auto;
      height: 540px;
      grid-template-rows: 100%;
      ${props =>
        props.layout === "text right"
          ? `
              grid-template-columns:  47% 53% ;
              grid-template-areas: " Pattern SolidColor ";
            `
          : `
              grid-template-columns:  53% 47% ;
              grid-template-areas: " SolidColor Pattern ";
            `}
    }
    @media screen and (min-width: ${MOBILE_MAX_WIDTH}px) {
      position: relative;
      min-width: 800px;
      max-width: 900px;
      margin: 0 auto;
      height: 505px;
      grid-template-rows: 100%;
      ${props =>
        props.layout === "text right"
          ? `
              grid-template-columns:  44% 56%;
              grid-template-areas: " Pattern SolidColor ";
            `
          : `
              grid-template-columns: 56% 44%;
              grid-template-areas: " SolidColor Pattern ";
            `}
    }
  `,
  HoverOverlay: styled.div`
    display: none;
    @media screen and (min-width: ${MOBILE_MAX_WIDTH}px) {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      display: flex;
    }
  `,
  HoverLeft: styled.div`
    flex: 1;

    ${props =>
      props.cardsNumber > 1
        ? `cursor: url(/images/left-arrow.svg) 0 0, auto;`
        : `cursor: auto;`}
  `,
  HoverRight: styled.div`
    flex: 1;

    ${props =>
      props.cardsNumber > 1
        ? `cursor: url(/images/right-arrow.svg) 0 0, auto;`
        : `cursor: auto;`}
  `,
  SolidColor: styled.div`
    grid-area: 2 / 1 / 4 / 4;
    background-color: #${props => props.bgColor};
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      grid-area: SolidColor;
      position: relative;
    }
  `,
  Pattern: styled.div`
    grid-area: 1 / 2 / 3 / 3;
    position: relative;
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      grid-area: Pattern;
    }
  `,
  Image: styled.div`
    position: absolute;
    height: 255px;
    width: 255px;
    transform: translate(-15%, -50%);
    top: 50%;
    @media screen and (min-width: ${IPHONE5_MAX_WIDTH}px) {
      height: 250px;
      width: 250px;
    }
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      height: 360px;
      width: 360px;

      ${props =>
        props.layout === "text right"
          ? `
          transform: translate(-9%, -50%);
            `
          : `
          transform: translate(9%, -50%);
          right: 0;
            `}
    }
    @media screen and (min-width: ${MOBILE_MAX_WIDTH}px) {
      height: 390px;
      width: 390px;
    }
  `,
  TagVertical: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: #${props => props.bgColor};
    width: 3rem;
    height: 100%;
    right: 0;
    transform: translate(0, -100%);
    overflow: hidden;
    @media screen and (min-width: ${IPHONE5_MAX_WIDTH}px) {
      width: 3.5rem;
    }
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      display: none;
    }
  `,
  TagHorizontal: styled.div`
    display: none;
    background-color: #${props => props.bgColor};
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 0;
      position: absolute;
      width: 3.5rem;
      right: 0;
      bottom: 50%;
      ${props =>
        props.layout === "text right"
          ? `
          right: 0;
      transform: translate(50%, 50%);
            `
          : `
         left: 0;
          transform: translate(-50%, 50%);
            `}
    }
  `,
  TagText: styled.div`
    writing-mode: vertical-rl;
    text-orientation: upright;
    font-family: ${FONTS.FIFTH_AVE_FONT_FAMILY};
    font-size: 22px;
    text-transform: uppercase;
    color: #${props => props.textColor};
    font-weight: 500;
    white-space: nowrap;
    height: 90%;
    text-align: center;
    overflow: hidden;
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      height: fit-content;
      font-size: 30px;
    }
  `,
}
