import styled from "styled-components"
import { COLORS, FONTS, IPAD_MINI_MAX_WIDTH } from "../../../styles"
import Img from "gatsby-image"

export default {
  Container: styled.div`
    padding: 30px 0;
    width: 100%;
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      padding: 80px 0 40px 0;
    }
  `,
  SectionTitle: styled.div`
    font-family: ${FONTS.SOHNE_LEICHT};
    font-size: 38px;
    color: ${COLORS.WHITE};
    text-align: center;
    padding: 50px 0 10px 0;
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      font-size: 48px;
      padding: 0 0 10px 0;
      transform: translateY(-20px);
    }
  `,
  SectionSubtitle: styled.div`
    font-family: ${FONTS.SOHNE_LEICHT};
    font-size: 15px;
    text-align: center;
    color: ${COLORS.WHITE};
    padding-bottom: 30px;
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      padding-bottom: 50px;
    }
  `,
  FlexContainer: styled.div`
    display: flex;
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      &: not(.swiper-pagination-instagram-carouselbullets) {
        ${props =>
          props.mouseLocation === "right"
            ? `cursor: url(/images/right-arrow.svg) 0 0, auto;`
            : `cursor: url(/images/left-arrow.svg) 0 0, auto;`};
      }
    }
    .swiper-pagination-instagram-carouselbullets {
      cursor: auto;
      bottom: 0;
      left: 50%;
      display: flex;
      justify-content: center;
      width: fit-content !important;
      transform: translate(-50%, -10px) !important;
      z-index: 10 !important;
    }
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      .swiper-pagination-instagram-carouselbullets {
        padding: 50px 50px 0 50px;
      }
    }
  `,
  Card: styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  `,
  Image: styled(Img)`
    margin-bottom: 10px;
    aspect-ratio: 1/1;
    height: 345px;
  `,
  Name: styled.div`
    font-size: 15px;
    font-family: ${FONTS.SOHNE_LEICHT};
    color: ${COLORS.WHITE};
  `,
}
