import styled from "styled-components"
import { COLORS, FONTS, MOBILE_MAX_WIDTH } from "../../../styles"

export default {
  GridContainer: styled.div`
    width: 100%;
    max-height: 405px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: ${MOBILE_MAX_WIDTH}px) {
    }
  `,
  Image: styled.div`
    grid-area: Image;
    height: 220px;
  `,
  Info: styled.div`
    height: 183px;
    grid-area: Info;
    background-color: #${props => props.bgColor};
    color: ${COLORS.BOTTLE_GREEN};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  Title: styled.div`
    font-size: 24px;
    font-family: ${FONTS.SOHNE_LEICHT};
    padding-bottom: 30px;
    text-transform: capitalize;
    text-align: center;
    height: 40px;
  `,
  Subtitle: styled.div`
    font-size: 12px;
    font-family: ${FONTS.SOHNE_HALBFETT};
    text-transform: uppercase;
    padding-bottom: 20px;
  `,
  Cta: styled.div`
    font-family: ${FONTS.SOHNE_BUCH};
    font-size: 12px;
    text-transform: uppercase;
    line-height: 1.2;
    border-bottom: 1px solid ${COLORS.BOTTLE_GREEN};
  `,
}
