import React from "react"
import Style from "./carousel-with-filter.style"
import CardWithTag, { CardWithTagProps } from "./card-with-tag"
import useResizeWidth from "../../../hooks/useResizeWidth"
import useMousePosition from "../../../hooks/useMousePosition"
import "../carousel.css"

import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, {
  EffectFade,
  Navigation,
  Pagination,
  Controller,
} from "swiper"
SwiperCore.use([Pagination, EffectFade, Navigation, Controller])
import "swiper/swiper.scss"
import "swiper/swiper-bundle.min.css"
import "swiper/swiper.min.css"

export interface CarouselWithFilterProps {
  content: {
    __typename: string
    title: string
    sectionBgHexColor: string
    ctaCardWithTag: CardWithTagProps[]
  }
}

const CarouselWithFilter = ({ content }: CarouselWithFilterProps) => {
  const allCards = content.ctaCardWithTag
  const tags = content.ctaCardWithTag
  const [cards, setCards] = React.useState(allCards)
  const [my_swiper, set_my_swiper] = React.useState({}) //mouseoverclick event

  const { width } = useResizeWidth()
  const { position } = useMousePosition()

  // mouseover event
  let mouseLocation
  if (position.x > width / 2) {
    mouseLocation = "right"
  } else if (position.x <= width / 2) {
    mouseLocation = "left"
  } else {
    return
  }

  React.useEffect(() => {
    setCards(allCards)
    return
  }, [])

  //filter cards by tagname on click
  const handleClick = value => {
    const filteredCards = allCards.filter(card =>
      card.tagName
        .trim()
        .toLowerCase()
        .includes(value.trim().toLowerCase())
    )
    setCards(filteredCards)
  }

  //filter for duplicate of tags
  const uniqueTags = Array.from(
    new Set(tags.map(t => t.tagName.trim().toLowerCase()))
  ).map(tagName => {
    return tags.find(
      t => t.tagName.trim().toLowerCase() === tagName.trim().toLowerCase()
    )
  })

  const renderTags = uniqueTags.map((card, i) => (
    <Style.TagButton key={i} onClick={() => handleClick(card.tagName)}>
      <Style.TagButtonText>{card.tagName}</Style.TagButtonText>
    </Style.TagButton>
  ))
  const renderTagsSlides = uniqueTags.map((card, i) => (
    <>
      <SwiperSlide
        key={i}
        style={{ maxWidth: "fit-content", flexBasis: "content" }}
      >
        <Style.TagButton onClick={() => handleClick(card.tagName)}>
          <Style.TagButtonText>{card.tagName}</Style.TagButtonText>
        </Style.TagButton>
      </SwiperSlide>
    </>
  ))
  return (
    <>
      <Style.Container bgColor={content.sectionBgHexColor}>
        <Style.SectionTitle>{content.title}</Style.SectionTitle>

        {width <= 700 ? (
          <Style.SectionFilter>
            <Swiper slidesPerView={"auto"} spaceBetween={20}>
              <SwiperSlide
                style={{
                  maxWidth: "fit-content",
                }}
              >
                <Style.TagButton onClick={() => setCards(allCards)}>
                  <Style.TagButtonText>all</Style.TagButtonText>
                </Style.TagButton>
              </SwiperSlide>
              {renderTagsSlides}
            </Swiper>
          </Style.SectionFilter>
        ) : (
          <Style.SectionFilter>
            <Style.TagButton onClick={() => setCards(allCards)}>
              <Style.TagButtonText>all</Style.TagButtonText>
            </Style.TagButton>
            {renderTags}
          </Style.SectionFilter>
        )}
        <Style.FlexContainer
          mouseLocation={mouseLocation}
          onClick={
            mouseLocation === "right"
              ? () => {
                  my_swiper.slideNext()
                }
              : () => {
                  my_swiper.slidePrev()
                }
          }
        >
          <Swiper
            onInit={ev => {
              set_my_swiper(ev)
            }}
            spaceBetween={80}
            slidesPerView={"auto"}
            className="swiper-filter-carousel"
            initialSlide={0}
            centeredSlides={true}
            loop={true}
            pagination={{
              bulletElement: "div",
              clickable: true,
              dynamicBullets: true,
              dynamicMainBullets: 3,
              bulletClass: "custom-bullet-white",
              modifierClass: "swiper-pagination-filter-carousel",
            }}
            containerModifierClass="swiper-container-padding-sm"
          >
            {cards.map((card, i) => (
              <SwiperSlide key={i}>
                <CardWithTag card={card} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Style.FlexContainer>
      </Style.Container>
    </>
  )
}

export default CarouselWithFilter
