import Styles from "@mapbox/mapbox-sdk/services/styles"
import styled from "styled-components"
import ClockIcon from "../../../images/clock.svg"
import PinIcon from "../../../images/geo-alt.svg"
import {
  COLORS,
  FONTS,
  MOBILE_MAX_WIDTH,
  IPAD_MINI_MAX_WIDTH,
  IPHONE5_MAX_WIDTH,
} from "../../../styles"

export default {
  FlexContainer: styled.div`
    padding: 200px 30px 30px 30px;
    display: flex;
    flex-direction: column;
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      ${props =>
        props.layout === "text right"
          ? `
          padding: 40px 60px 80px 40px;
            `
          : `
          padding: 40px 60px 80px 60px;
            `}
    }
  `,
  Title: styled.div`
    font-family: ${FONTS.SIGNIFIER_REGULAR_FONT_FAMILY};
    color: ${COLORS.HOLLY_GREEN};
    font-size: 35px;
    padding-bottom: 10px;
    @media screen and (min-width: ${MOBILE_MAX_WIDTH}px) {
      font-size: 40px;
    }
    line-height: 1.1;
  `,
  Description: styled.div`
    font-family: ${FONTS.SOHNE_LEICHT};
    font-size: 15px;
    color: ${COLORS.BOTTLE_GREEN};
    padding-bottom: 30px;
    line-height: 1.5;
    @media screen and (min-width: ${MOBILE_MAX_WIDTH}px) {
      padding-bottom: 50px;
    }
  `,
  DetailsContainer: styled.div`
    display: flex;
    flex-direction: column;
    color: ${COLORS.BOTTLE_GREEN};
    @media screen and (min-width: ${MOBILE_MAX_WIDTH}px) {
      flex-direction: row;
      justify-content: space-between;
    }
  `,
  Detail: styled.div`
    padding-bottom: 30px;
    flex: 1;
    @media screen and (min-width: ${MOBILE_MAX_WIDTH}px) {
      &:first-of-type {
        margin-right: 10px;
      }
    }
  `,
  DetailTitle: styled.div`
    font-family: ${FONTS.SOHNE_HALBFETT};
    font-size: 12px;
  `,
  DetailDescription: styled.div`
    font-family: ${FONTS.SOHNE_LEICHT};
    font-size: 13px;
    display: flex;
    align-items: center;
    line-height: 1.5;
  `,
  ClockIcon: styled(ClockIcon)`
    height: 13px;
    width: 13px;
    margin-right: 10px;
  `,
  PinIcon: styled(PinIcon)`
    height: 13px;
    width: 13px;
    margin-right: 10px;
  `,
}
