import React from "react"
import { FluidObject } from "gatsby-image"
import Style from "./info-card.style"

interface InfoCardProps {
  layout: string
  card: {
    image: {
      fluid: FluidObject
    }
    pattern: {
      fluid: FluidObject
    }
    eventTime: string
    eventDescription: string
    location: string
    title: string
    tip: string
  }
}

const InfoCard = ({ card, layout }: InfoCardProps) => {
  return (
    <>
      <Style.FlexContainer layout={layout}>
        <Style.Title>{card.title}</Style.Title>
        <Style.Description>{card.eventDescription}</Style.Description>
        <Style.DetailsContainer>
          <Style.Detail>
            <Style.DetailTitle>WHERE & WHEN</Style.DetailTitle>
            <Style.DetailDescription>
              <Style.PinIcon />
              {card.location}
            </Style.DetailDescription>
            <Style.DetailDescription>
              <Style.ClockIcon />
              {card.eventTime}
            </Style.DetailDescription>
          </Style.Detail>
          <Style.Detail>
            <Style.DetailTitle>FIFTH TIP</Style.DetailTitle>
            <Style.DetailDescription>{card.tip}</Style.DetailDescription>
          </Style.Detail>
        </Style.DetailsContainer>
      </Style.FlexContainer>
    </>
  )
}

export default InfoCard
