import React from "react"
import Style from "./influencer-carousel.style"
import Img, { FluidObject } from "gatsby-image"
import "../carousel.css"

import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, {
  EffectFade,
  Navigation,
  Pagination,
  Controller,
} from "swiper"
SwiperCore.use([Pagination, EffectFade, Navigation, Controller])
import "swiper/swiper.scss"
import "swiper/swiper-bundle.min.css"
import "swiper/swiper.min.css"

export interface InfluencerCarouselCard {
  title: string
  hoverBgHexColor: string
  subtitle: string
  image: {
    fluid: FluidObject
    title: string
  }
  description: {
    description: string
  }
}

export interface InfluencerCarouselProps {
  content: {
    __typename: string
    influencerCard: InfluencerCarouselCard[]
  }
}

const InfluencerCarousel = ({ content }: InfluencerCarouselProps) => {
  const cards = content.influencerCard.map((card, i) => (
    <Style.CardContainer key={i}>
      <Style.HoverOverlay bgColor={card.hoverBgHexColor}>
        <Style.HoverText>{card.description.description}</Style.HoverText>
      </Style.HoverOverlay>
      <Style.Image>
        <Img
          fluid={card.image.fluid}
          alt={card.image.title}
          style={{ width: "100%", height: "100%" }}
        />
      </Style.Image>
      <Style.Title>{card.title}</Style.Title>
      <Style.Subtitle>{card.subtitle}</Style.Subtitle>
    </Style.CardContainer>
  ))
  const carouselCards = content.influencerCard.map((card, i) => (
    <SwiperSlide key={i}>
      <Style.CardContainer>
        <Style.Image>
          <Img
            fluid={card.image.fluid}
            alt={card.image.title}
            style={{ width: "100%", height: "100%" }}
          />
        </Style.Image>
        <Style.Title>{card.title}</Style.Title>
        <Style.Subtitle>{card.subtitle}</Style.Subtitle>
        <Style.Description>{card.description.description}</Style.Description>
      </Style.CardContainer>
    </SwiperSlide>
  ))
  return (
    <>
      <Style.FlexContainer>{cards}</Style.FlexContainer>
      <Style.Carousel>
        <Swiper
          slidesPerView={1.7}
          centeredSlides={true}
          initialSlide={1}
          pagination={{
            bulletElement: "div",
            clickable: true,
            bulletClass: "custom-bullet-white-regular",
            modifierClass: "swiper-pagination-influencer-carousel",
          }}
        >
          {carouselCards}
        </Swiper>
      </Style.Carousel>
    </>
  )
}

export default InfluencerCarousel
