import React from "react"
import Img, { FluidObject } from "gatsby-image"
import Style from "./banner-with-cta.style"
import useResizeWidth from "../../../hooks/useResizeWidth"

export interface BannerWithCtaProps {
  content: {
    __typename: string
    ctaHexColor: string
    ctaText: string
    ctaUrl: string
    desktopImage: {
      fluid: FluidObject
      title: string
    }
    mobileImage: {
      fluid: FluidObject
      title: string
    }
    sectionBgHexColor: string
    textHexColor: string
    subtitle: string
    title: string
  }
}

const BannerWithCta = ({ content }: BannerWithCtaProps) => {
  const { width } = useResizeWidth()

  return (
    <>
      <Style.Background>
        <Img
          fluid={
            width > 700 ? content.desktopImage.fluid : content.mobileImage.fluid
          }
          alt={content.desktopImage.title}
          style={{ width: "100%", height: "100%" }}
        />
        <Style.ContainerOverlay textColor={content.textHexColor}>
          <Style.Title>{content.title}</Style.Title>
          <Style.Subtitle>{content.subtitle}</Style.Subtitle>
          <Style.Button
            href={content.ctaUrl}
            bgColor={content.ctaHexColor}
            textColor={content.textHexColor}
          >
            {content.ctaText}
          </Style.Button>
        </Style.ContainerOverlay>
      </Style.Background>
      <Style.CtaBoxMobile bgColor={content.sectionBgHexColor}>
        <Style.Title>{content.title}</Style.Title>
        <Style.Subtitle>{content.subtitle}</Style.Subtitle>
        <Style.Button
          href={content.ctaUrl}
          bgColor={content.ctaHexColor}
          textColor={content.textHexColor}
        >
          {content.ctaText}
        </Style.Button>
      </Style.CtaBoxMobile>
    </>
  )
}

export default BannerWithCta
