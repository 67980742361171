import styled from "styled-components"
import {
  COLORS,
  FONTS,
  MOBILE_MAX_WIDTH,
  IPAD_MINI_MAX_WIDTH,
} from "../../../styles"

export default {
  Background: styled.div`
    width: 100%;
    height: 300px;
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      height: 450px;
      position: relative;
    }
  `,
  ContainerOverlay: styled.div`
    display: none;
    color: #${props => props.textColor};
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      height: 450px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  `,
  CtaBoxMobile: styled.div`
    min-height: 300px;
    background-color: #${props => props.bgColor};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      display: none;
    }
  `,
  Title: styled.div`
    font-family: ${FONTS.SIGNIFIER_REGULAR_FONT_FAMILY};
    font-size: 40px;
    max-width: 380px;
    line-height: 1.2;
    text-align: center;
    padding-bottom: 20px;
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      font-size: 50px;
      padding-bottom: 10px;
    }
  `,
  Subtitle: styled.div`
    font-family: ${FONTS.SOHNE_LEICHT};
    font-size: 15px;
    width: 325px;
    text-align: center;
    padding-bottom: 30px;
  `,
  Button: styled.a`
    font-family: ${FONTS.SOHNE_LEICHT};
    text-transform: uppercase;
    font-size: 15px;
    background-color: #${props => props.bgColor};
    color: #${props => props.textColor};
    width: 170px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin-bottom: 30px;
    transition: all 0.3s ease;
    &:hover {
      background-color: #${props => props.textColor};
      color: #${props => props.bgColor};
    }
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      margin-bottom: 0px;
    }
  `,
}
