import React from "react"
import Style from "./card.style"
import Img, { FluidObject } from "gatsby-image"
import { Link } from "gatsby"

export interface CarouselSimpleCardProps {
  card: {
    bgHexColor: string
    ctaLabel: string
    ctaUrl: string
    image: {
      fluid: FluidObject
      title: string
    }
    subtitle: string
    title: string
  }
}

const CardWithTag = ({ card }: CarouselSimpleCardProps) => {
  return (
    <>
      <Style.GridContainer>
        <Style.Image>
          <Img
            fluid={card.image.fluid}
            alt={card.image.title}
            style={{ width: "100%", height: "100%" }}
          />
        </Style.Image>
        <Style.Info bgColor={card.bgHexColor}>
          <Style.Subtitle>{card.title}</Style.Subtitle>
          <Style.Title>{card.subtitle.substring(0, 55)}</Style.Title>
          <Style.Cta>
            <Link style={{ textDecoration: "none" }} to={card.ctaUrl}>
              {card.ctaLabel}
            </Link>
          </Style.Cta>
        </Style.Info>
      </Style.GridContainer>
    </>
  )
}

export default CardWithTag
