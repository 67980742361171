import React from "react"
import Style from "./influencer-full-width.style"
import Img, { FluidObject } from "gatsby-image"
import "../carousel.css"

export interface InfluencerFullWidthProps {
  content: {
    __typename: string
    title?: string
    subtitle?: string
    image: {
      title?: string
      fluid?: FluidObject
    }
  }
}

const InfluencerFullWidth = ({ content }: InfluencerFullWidthProps) => {
  return (
    <>
      <Style.Container>
        <Style.Image>
          <Img
            fluid={content.image.fluid}
            alt={content.image.title}
            style={{ width: "100%", height: "100%" }}
          />
        </Style.Image>
        <Style.Title>{content.title}</Style.Title>
        <Style.Subtitle>{content.subtitle}</Style.Subtitle>
      </Style.Container>
    </>
  )
}

export default InfluencerFullWidth
