import styled from "styled-components"
import { COLORS, FONTS, IPAD_MINI_MAX_WIDTH } from "../../../styles"

export default {
  Carousel: styled.div`
    display: flex;
    padding: 30px 0;
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      display: none;
      padding: 40px 0;
    }
  `,
  FlexContainer: styled.div`
    display: none;
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      display: flex;
      justify-content: center;
      gap: 15px;
      max-width: 960px;
      margin: 0 auto;
    }
  `,
  HoverOverlay: styled.div`
    opacity: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #${props => props.bgColor};
    z-index: 2;
    transition: 0.3s ease;
    &:hover {
      opacity: 1;
    }
  `,
  CardContainer: styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${COLORS.WHITE};
    position: relative;
    padding-bottom: 60px;
    &:hover ~ HoverOverlay {
      opacity: 1;
    }
  `,

  HoverText: styled.div`
    color: ${COLORS.BOTTLE_GREEN};
    margin: 0 20px;
    font-family: ${FONTS.SOHNE_LEICHT};
    font-size: 15px;
    text-align: center;
  `,
  Image: styled.div`
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 30px;
  `,
  Title: styled.div`
    font-family: ${FONTS.SOHNE_LEICHT};
    font-size: 30px;
    padding-bottom: 15px;
  `,
  Subtitle: styled.div`
    font-family: ${FONTS.SOHNE_HALBFETT};
    font-size: 12px;
    padding-bottom: 20px;
    text-transform: uppercase;
  `,
  Description: styled.div`
    font-family: ${FONTS.SOHNE_LEICHT};
    font-size: 15px;
    text-align: center;
    width: 90%;
  `,
}
