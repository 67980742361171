import styled from "styled-components"
import { COLORS, FONTS, IPAD_MINI_MAX_WIDTH } from "../../../styles"

export default {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${COLORS.WHITE};
    padding: 30px 0;
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      padding: 40px 0;
    }
  `,
  Image: styled.div`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 20px;
  `,
  Title: styled.div`
    font-family: ${FONTS.SOHNE_LEICHT};
    font-size: 30px;
    padding-bottom: 20px;
    max-width: 350px;
    line-height: 1.1;
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      max-width: 400px;
    }
    text-align: center;
  `,
  Subtitle: styled.div`
    font-family: ${FONTS.SOHNE_HALBFETT};
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
  `,
}
