import React from "react"
import Img, { FluidObject } from "gatsby-image"
import Style from "./main-event-component.style"
import "../carousel.css"
import InfoCard from "./info-card"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, {
  EffectFade,
  Navigation,
  Pagination,
  Controller,
} from "swiper"
SwiperCore.use([Pagination, EffectFade, Navigation, Controller])
import "swiper/swiper.scss"
import "swiper/swiper-bundle.min.css"
import "swiper/swiper.min.css"

export interface InfoCard {
  image: {
    fluid: FluidObject
  }
  pattern: {
    fluid: FluidObject
  }
  eventTime: string
  eventDescription: string
  location: string
  title: string
  tip: string
}

export interface MainEventComponentProps {
  content: {
    __typename: string
    title: string
    tagTextHexColor?: string
    tagBgHexColor?: string
    tag?: string
    mainBgHexColor: string
    layout: string
    infoCards: InfoCard[]
  }
}

const MainEventComponent = ({ content }: MainEventComponentProps) => {
  const [my_swiper, set_my_swiper] = React.useState({})

  return (
    <>
      <Swiper
        onInit={ev => {
          set_my_swiper(ev)
        }}
        allowSlideNext={true}
        effect={"fade"}
        slidesPerView={1}
        centeredSlides
        loop={true}
        pagination={{
          bulletElement: "div",
          clickable: true,
          dynamicBullets: content.infoCards.length > 1 ? true : false,
          dynamicMainBullets: 3,
          bulletClass: "custom-bullet-black",
          modifierClass:
            content.layout === "text right"
              ? "swiper-pagination-custom-right"
              : "swiper-pagination-custom-left",
        }}
      >
        {content.infoCards.map((card, i) => (
          <SwiperSlide key={i}>
            <Style.GridContainer layout={content.layout}>
              <Style.HoverOverlay>
                <Style.HoverLeft
                  onClick={() => {
                    my_swiper.slidePrev()
                  }}
                  cardsNumber={content.infoCards.length}
                ></Style.HoverLeft>
                <Style.HoverRight
                  onClick={() => {
                    my_swiper.slideNext()
                  }}
                  cardsNumber={content.infoCards.length}
                ></Style.HoverRight>
              </Style.HoverOverlay>
              <Style.SolidColor bgColor={content.mainBgHexColor}>
                {content.tag && (
                  <Style.TagHorizontal
                    bgColor={content.tagBgHexColor}
                    layout={content.layout}
                  >
                    <Style.TagText textColor={content.tagTextHexColor}>
                      {content.tag}
                    </Style.TagText>
                  </Style.TagHorizontal>
                )}

                <div style={{ width: "100%", overflow: "hidden" }}>
                  <InfoCard card={card} layout={content.layout} />
                </div>
              </Style.SolidColor>

              <Style.Pattern>
                <Img
                  fluid={card.pattern.fluid}
                  style={{ width: "100%", height: "100%" }}
                />
                <Style.Image layout={content.layout}>
                  <Img
                    fluid={card.image.fluid}
                    style={{ width: "100%", height: "100%" }}
                  />
                </Style.Image>
                {content.tag && (
                  <Style.TagVertical bgColor={content.tagBgHexColor}>
                    <Style.TagText textColor={content.tagTextHexColor}>
                      {content.tag}
                    </Style.TagText>
                  </Style.TagVertical>
                )}
              </Style.Pattern>
            </Style.GridContainer>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}

export default MainEventComponent
