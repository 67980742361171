import styled from "styled-components"
import { COLORS, FONTS, IPAD_MINI_MAX_WIDTH } from "../../../styles"

export default {
  Container: styled.div`
    width: 100%;
    padding: 30px 0;
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      padding: 80px 0 40px 0;
    }
  `,
  SectionTitle: styled.div`
    font-family: ${FONTS.SOHNE_LEICHT};
    font-size: 38px;
    color: ${COLORS.WHITE};
    text-align: center;
    padding: 50px 0 20px 0;

    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      font-size: 48px;
      padding: 0 0 10px 0;
      transform: translateY(-20px);
    }
  `,
  SectionSubtitle: styled.div`
    font-family: ${FONTS.SOHNE_LEICHT};
    font-size: 15px;
    text-align: center;
    color: ${COLORS.WHITE};
    padding-bottom: 30px;
    width: 90%;
    margin: 0 auto;
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      width: 100%;
      padding-bottom: 50px;
    }
  `,
  FlexContainer: styled.div`
    display: flex;
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      display: flex;
      &: not(.swiper-pagination-simple-carouselbullets) {
        ${props =>
          props.mouseLocation === "right"
            ? `cursor: url(/images/right-arrow.svg) 0 0, auto;`
            : `cursor: url(/images/left-arrow.svg) 0 0, auto;`};
      }
    }
    .swiper-pagination-simple-carouselbullets {
      cursor: auto;
      bottom: 0;
      left: 50%;
      display: flex;
      justify-content: center;
      width: fit-content !important;
      transform: translate(-50%, -10px) !important;
      z-index: 10 !important;
    }
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      .swiper-pagination-simple-carouselbullets {
        padding: 40px 40px 0 40px;
      }
    }
    .swiper-container.swiper-container-simple-carouselinitialized {
      padding-bottom: 40px;
    }
    @media screen and (min-width: 700px) {
      .swiper-container.swiper-container-simple-carouselinitialized {
        padding-bottom: 70px;
      }
    }
  `,
}
