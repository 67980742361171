import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import Style from "../components/holiday-page/holiday-page.style"
import SEO from "../components/common/seo"
import HolidayPageHero from "../components/holiday-page/hero/hero"
import MainEventComponent, {
  MainEventComponentProps,
} from "../components/holiday-page/event-component/main-event-component"
import InfluencerCarousel, {
  InfluencerCarouselProps,
} from "../components/holiday-page/influencer-carousel/influencer-carousel"
import InfluencerFullWidth, {
  InfluencerFullWidthProps,
} from "../components/holiday-page/influencer-full-width/influencer-full-width"
import CarouselWithFilter, {
  CarouselWithFilterProps,
} from "../components/holiday-page/carousel-with-filter/carousel-with-filter"
import CarouselSimple, {
  CarouselSimpleProps,
} from "../components/holiday-page/carousel-simple/carousel-simple"
import NewsGrid, {
  NewsGridProps,
} from "../components/holiday-page/news-grid/news-grid"
import BannerWithCta, {
  BannerWithCtaProps,
} from "../components/holiday-page/banner-with-cta/banner-with-cta"
import InstagramCarousel, {
  InstagramCarouselProps,
} from "../components/holiday-page/instagram-carousel/instagram-carousel"

export interface Props {
  data: {
    contentfulHolidayLandingPage: {
      title: string
      description: string
      bannerMediaFormat: string
      gif: {
        file: {
          url: string
        }
        title: string
      }
      video: {
        file: {
          url: string
          fileName: string
          contentType: string
        }
        title: string
      }
      sections: {
        ContentfulInstagramCarousel: InstagramCarouselProps
        ContentfulBannerWithCta: BannerWithCtaProps
        ContentfulNewsGridComponent: NewsGridProps
        ContentfulCarouselSimple: CarouselSimpleProps
        ContentfulCarouselWithFilter: CarouselWithFilterProps
        ContentfulInfluencerFullWidthComponent: InfluencerFullWidthProps
        ContentfulInfluencerCarouselComponent: InfluencerCarouselProps
        ContentfulHolidayEventComponent: MainEventComponentProps
      }[]
    }
  }
}

function displaySections(section: any, i: number) {
  switch (section.__typename) {
    case "ContentfulHolidayEventComponent":
      return (
        <div key={i} id="5th_holiday_events">
          <MainEventComponent content={section} />
        </div>
      )
    case "ContentfulInfluencerCarouselComponent":
      return <InfluencerCarousel key={i} content={section} />
    case "ContentfulInfluencerFullWidthComponent":
      return <InfluencerFullWidth key={i} content={section} />
    case "ContentfulCarouselWithFilter":
      return <CarouselWithFilter key={i} content={section} />
    case "ContentfulCarouselSimple":
      return <CarouselSimple key={i} content={section} />
    case "ContentfulNewsGridComponent":
      return <NewsGrid key={i} content={section} />
    case "ContentfulBannerWithCta":
      return <BannerWithCta key={i} content={section} />
    case "ContentfulInstagramCarousel":
      return <InstagramCarousel key={i} content={section} />
    default:
      return null
  }
}

const HolidayPage = ({ data }: Props) => {
  const { title, description, sections } = data.contentfulHolidayLandingPage

  return (
    <>
      <Layout>
        <Style.Root>
          <SEO title={title} description={description} />
          <HolidayPageHero data={data} />
          <div style={{ paddingTop: "80px" }}>
            {sections.map((section: any, i: number) =>
              displaySections(section, i)
            )}
          </div>
        </Style.Root>
      </Layout>
    </>
  )
}

export const query = graphql`
  {
    contentfulHolidayLandingPage {
      title
      description
      bannerMediaFormat
      mobileImage {
        file {
          url
        }
        title
      }
      gif {
        file {
          url
        }
        title
      }
      video {
        file {
          url
          fileName
          contentType
        }
        title
      }
      sections {
        ... on Node {
          ... on ContentfulInstagramCarousel {
            __typename
            title
            subtitle
            cards {
              image {
                fluid {
                  ...GatsbyContentfulFluid
                }
                title
              }
              name
            }
          }
        }
        ... on Node {
          ... on ContentfulBannerWithCta {
            __typename
            ctaHexColor
            ctaText
            ctaUrl
            desktopImage {
              fluid {
                ...GatsbyContentfulFluid
              }
              title
            }
            mobileImage {
              fluid {
                ...GatsbyContentfulFluid
              }
              title
            }
            sectionBgHexColor
            textHexColor
            subtitle
            title
          }
        }
        ... on Node {
          ... on ContentfulNewsGridComponent {
            __typename
            sectionBgHexColor
            linkText
            linkUrl
            title
            news {
              author
              externalLink
              tag
              title
              image {
                fluid {
                  ...GatsbyContentfulFluid
                }
                title
              }
            }
          }
        }
        ... on Node {
          ... on ContentfulCarouselSimple {
            __typename
            cards {
              bgHexColor
              ctaLabel
              ctaUrl
              image {
                fluid {
                  ...GatsbyContentfulFluid
                }
                title
              }
              subtitle
              title
            }
            title
            subtitle
          }
        }
        ... on Node {
          ... on ContentfulCarouselWithFilter {
            __typename
            title
            sectionBgHexColor
            ctaCardWithTag {
              cardBgHexColor
              ctaLabel
              ctaUrl
              tagName
              tagBgHexColor
              title
              pattern {
                fluid {
                  ...GatsbyContentfulFluid
                }
                title
              }
              image {
                fluid {
                  ...GatsbyContentfulFluid
                }
                title
              }
            }
          }
        }
        ... on Node {
          ... on ContentfulInfluencerFullWidthComponent {
            __typename
            image {
              title
              fluid {
                ...GatsbyContentfulFluid
              }
            }
            title
            subtitle
          }
        }
        ... on Node {
          ... on ContentfulInfluencerCarouselComponent {
            __typename
            influencerCard {
              title
              hoverBgHexColor
              image {
                fluid {
                  ...GatsbyContentfulFluid
                }
                title
              }
              subtitle
              description {
                description
              }
            }
          }
        }
        ... on Node {
          ... on ContentfulHolidayEventComponent {
            __typename
            title
            tagTextHexColor
            tagBgHexColor
            tag
            mainBgHexColor
            layout
            infoCards {
              id
              image {
                fluid {
                  ...GatsbyContentfulFluid
                }
              }
              pattern {
                fluid {
                  ...GatsbyContentfulFluid
                }
              }
              eventTime
              eventDescription
              location
              title
              tip
            }
          }
        }
      }
    }
  }
`

export default HolidayPage
