import React from "react"
import Style from "./news-grid.style"
import Img, { FluidObject } from "gatsby-image"
import { Link } from "gatsby"
import "../carousel.css"

import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, {
  EffectFade,
  Navigation,
  Pagination,
  Controller,
} from "swiper"
SwiperCore.use([Pagination, EffectFade, Navigation, Controller])
import "swiper/swiper.scss"
import "swiper/swiper-bundle.min.css"
import "swiper/swiper.min.css"

export interface NewsCard {
  author: string
  externalLink: string
  tag: string
  title: string
  image: {
    fluid: FluidObject
    title: string
  }
}

export interface NewsGridProps {
  content: {
    __typename: string
    sectionBgHexColor: string
    linkText: string
    linkUrl: string
    title: string
    news: NewsCard[]
  }
}

const NewsGrid = ({ content }: NewsGridProps) => {
  const cards = content.news.map((card, i) => (
    <Style.CardContainer key={i}>
      <Style.Image>
        <Img
          fluid={card.image.fluid}
          alt={card.image.title}
          style={{ width: "100%", height: "100%" }}
        />
        <Style.Tag>{card.author}</Style.Tag>
      </Style.Image>
      <Style.Title>{card.title}</Style.Title>
      <Style.Link href={card.externalLink}>read more</Style.Link>
    </Style.CardContainer>
  ))

  const carouselCards = content.news.map((card, i) => (
    <SwiperSlide key={i}>
      <Style.CardContainer>
        <Style.Image>
          <Img
            fluid={card.image.fluid}
            alt={card.image.title}
            style={{ width: "100%", height: "100%" }}
          />
          <Style.Tag>{card.author}</Style.Tag>
        </Style.Image>
        <Style.Title>{card.title}</Style.Title>
        <Style.Link href={card.externalLink}>read more</Style.Link>
      </Style.CardContainer>
    </SwiperSlide>
  ))

  return (
    <>
      <Style.Container bgColor={content.sectionBgHexColor}>
        <Style.SectionTitle>{content.title}</Style.SectionTitle>
        <Style.SectionSubtitle>
          <Link to={content.linkUrl} style={{ textDecoration: "none" }}>
            {content.linkText}
          </Link>
        </Style.SectionSubtitle>
        <Style.FlexContainer>{cards}</Style.FlexContainer>
        <Style.Carousel>
          <Swiper
            slidesPerView={1}
            centeredSlides={true}
            initialSlide={0}
            containerModifierClass="swiper-container-padding-lg"
            pagination={{
              clickable: true,
              bulletElement: "div",
              bulletClass: "custom-bullet-white-regular",
              modifierClass: "swiper-pagination-news-carousel",
            }}
          >
            {carouselCards}
          </Swiper>
        </Style.Carousel>
      </Style.Container>
    </>
  )
}

export default NewsGrid
