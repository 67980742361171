import styled from "styled-components"
import { COLORS, FONTS, IPAD_MINI_MAX_WIDTH } from "../../../styles"
import { CTA } from "../../common/cta"

export default {
  Container: styled.div`
    padding-bottom: 40px;
    background-color: #${props => props.bgColor};
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      margin-top: 40px;
      padding-bottom: 80px;
    }
  `,
  SectionTitle: styled.div`
    font-family: ${FONTS.SOHNE_LEICHT};
    font-size: 38px;
    color: ${COLORS.WHITE};
    text-align: center;
    padding: 50px 0 20px 0;
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      font-size: 48px;
      padding: 0 0 20px 0;
      transform: translateY(-20px);
    }
  `,
  SectionSubtitle: styled(CTA)`
    font-family: ${FONTS.SOHNE_BUCH};
    font-size: 12px;
    color: ${COLORS.WHITE};
    background-color: transparent;
    width: fit-content;
    margin: 0 auto;
    text-transform: uppercase;
    cursor: pointer;
  `,

  CardContainer: styled.div`
    display: flex;
    flex-direction: column;
  `,
  FlexContainer: styled.div`
    display: none;
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      display: flex;
      padding: 50px 30px 0 30px;
      margin: 0 auto;
      gap: 60px;
      max-width: 1000px;
      justify-content: space-around;
    }
  `,
  Carousel: styled.div`
    display: flex;
    padding: 30px 0 0 0;
    width: 340px;
    margin: 0 auto;
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      display: none;
    }
  `,
  Image: styled.div`
    width: 340px;
    height: 340px;
    padding-bottom: 20px;
    position: relative;
    @media screen and (min-width: ${IPAD_MINI_MAX_WIDTH}px) {
      width: 285px;
      height: 285px;
    }
  `,
  Title: styled.div`
    font-size: 24px;
    color: ${COLORS.WHITE};
    font-family: ${FONTS.SOHNE_LEICHT};
    text-transform: capitalize;
    padding-bottom: 40px;
    width: 285px;
  `,
  Link: styled(CTA)`
    font-size: 12px;
    color: ${COLORS.WHITE};
    font-family: ${FONTS.SOHNE_BUCH};
    text-transform: uppercase;
    width: fit-content;
  `,
  Tag: styled.div`
    position: absolute;
    background-color: #d9b5d6;
    padding: 8px;
    width: fit-content;
    font-size: 12px;
    color: ${COLORS.BOTTLE_GREEN};
    font-family: ${FONTS.SOHNE_HALBFETT};
    text-transform: uppercase;
    line-height: 1;
    bottom: 0;
    transform: translateY(-20px);
  `,
}
